const Inventory = [
  {
    src: "https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/5bf0979a-96e2-4b86-b546-cfc20c4c5ef5/cosmic-unity-2-aja-wilson-basketball-shoes-L53XJP.png",
    name: "Nike Cosmic Unity 2 \"A'ja Wilson",
    price: "$87.97",
    desc: "Celebrate the love and joy of the game with the Nike Cosmic Unity 2. Made with at least 20% recycled content by weight, it provides enhanced responsiveness and support. This shoe will help keep you fresh and secure without overloading it with extra ounces, so that you can focus on locking down the perimeter defensively or starting the fast break after a rebound.",
  },
  {
    src: "https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/6fab33e3-36d0-48e9-9fa0-e42d903b2a5c/giannis-immortality-basketball-shoes-p9QlJF.png",
    name: "Giannis Immortality",
    price: "$74.97",
    desc: "The future of basketball is positionless, and nobody embodies this evolution like MVP Giannis Antetokounmpo. The Giannis Immortality is a game shoe for players who love Giannis' multidimensional game and want a piece of his freaky style.",
  },
  {
    src: "https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/6f4166d8-0d07-4a2c-9ffa-01aae5362796/flyby-mid-3-basketball-shoes-sNRbLR.png",
    name: "Nike Fly.By Mid 3",
    price: "$55.97",
    desc: "Cushioning, ankle support and traction are essential on the basketball court. The Nike Fly.By Mid 3 delivers exactly what's needed to help players excel on offense and defense. Designed to look as comfortable as it feels, the mid-top sneaker has plush, energy-returning foam to help keep you fresh through 4 quarters.",
  },
  {
    src: "https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/37c403fe-1553-4498-8c78-e4175b2ff1d9/lebron-witness-6-team-basketball-shoes-d6BdZB.png",
    name: "LeBron Witness 6 (Team)",
    price: "$65.97",
    desc: "For this iteration of the LeBron Witness, we swapped out Zoom Air for visible Max Air cushioning—LeBron's favorite—to give you cushioning and provide a responsive sensation. The lightweight, reinforced mesh upper helps keep you contained all around, from the webbing that harnesses your forefoot to the external molded pieces that lock in your heel.",
  },
  {
    src: "https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/36e52547-86a5-4800-96d4-329c17b1ec87/lebron-witness-6-basketball-shoes-HXbMw7.png",
    name: "LeBron Witness 6",
    price: "$105",
    desc: "For this iteration of the LeBron Witness, we swapped out Zoom Air for visible Max Air cushioning—LeBron's favorite—to help dissipate impact forces and provide a responsive sensation. The lightweight, reinforced mesh upper keeps you contained all around, from the webbing that harnesses your forefoot to the external molded pieces that lock in your heel.",
  },
  {
    src: "https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/e0623112-617b-45e1-a6d3-e57a8d43dfb7/kd15-team-basketball-shoes-RBczWt.png",
    name: "KD15 (Team)",
    price: "$127.97",
    desc: "Kevin Durant thrives in big games. When the stakes are high, he'll play every minute if it means getting a crucial victory. The KD15 is ready to go the distance. Lighter than his previous signature shoe, it helps you stay bouncy and comfortable in crunch time. The ride is as smooth as KD’s pull-up jumper and the cushioning is as plush as his right-to-left crossover in traffic, so that you can feel contained when your team needs you the most.",
  },
  {
    src: "https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/f351e174-10d8-43d4-bf7d-c57a16caa26d/kd-trey-5-x-basketball-shoes-cNfPMN.png",
    name: "KD Trey 5 X",
    price: "$95",
    desc: "With its lightweight upper and plush support system, the KD Trey 5 X can help you float like KD, waiting for the perfect moment to drive to the hoop. A secure midfoot strap is suited for scoring binges and defensive stands, so that you can lock in and keep winning.",
  },
  {
    src: "https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/a30234be-ab9f-40d6-a939-d06d099b14c1/jordan-why-not-6-mens-shoes-T5Gl76.png",
    name: "Jordan Why Not .6",
    price: "$140",
    desc: "Russell Westbrook's 6th signature shoe is—you guessed it—all about speed. To get you goin' as fast as possible, we've wrapped the rubber outsole nearly up to the midsole, so you're not gonna slip when you explode from 0 to 100. Added security comes from the interior bootie that keeps you strapped in as you jet across the court. It's all held together by an outer shroud and fastened with a zippered collar that spells out Russell's signature question: \"Why Not?",
  },
  {
    src: "https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/1f3acf31-c349-4bc7-b5d2-d92b0b07f6e8/lebron-witness-7-basketball-shoes-43zKtc.png",
    name: "LeBron Witness 7",
    price: "$59.97",
    desc: "The longer LeBron’s legendary career continues, the more his game needs a design that doesn’t weigh him down, yet can still control all that sublime power. So, we’ve created our lightest LeBron model but with the kind of bankable support that can help you stay in front of your opponent with the game on the line. Full-length Max Air cushioning helps you take off and land confidently, while lightweight mesh blends with containment cables to help keep you locked in for 4 quarters or more.",
  },
  {
    src: "https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/5a31181e-9bcb-4b27-b492-e9836cbb7a77/precision-6-basketball-shoes-l7zDFB.png",
    name: "Nike Precision 6",
    price: "$75",
    desc: "Create space, stop on a dime, shoot off the dribble—do it all with the Nike Precision 6. It's designed to enable quick players to shift speeds and change directions while staying in control. From the plush collar and tongue to the modified herringbone traction, the agile low-top lets you make the most of your skills while pushing the tempo of the game.",
  },
  {
    src: "https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/793a5dfb-c05a-47c0-b676-362d6a1ddbd9/air-jordan-xxxvii-basketball-shoes-MttxV5.png",
    name: "Air Jordan XXXVII",
    price: "$120.97",
    desc: "You've got the hops and the speed—lace up in shoes that enhance what you bring to the court. The latest AJ is all about takeoffs and landings, with multiple Air units to get you off the ground and our signature Formula 23 foam to cushion your impact. Up top, you'll find layers of tough, reinforced leno-weave fabric that'll keep you contained—and leave your game uncompromised.",
  },
  {
    src: "https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/b1f64f7c-a67f-49b0-b4a0-7c4bf339db7d/lebron-19-basketball-shoes-VkqHgW.png",
    name: "LeBron 19",
    price: "$99.97",
    desc: "LeBron thrives when stakes are high and the pressure’s on. The LeBron 19 harnesses that energy with a locked-in fit and an updated cushioning system. The snug inner sleeve is pulled together by a sculpted overlay that the laces feed through to help prevent the foot from moving inside the shoe. Cushioned pods around the collar and tongue add comfort while reducing weight, giving players the secure feel and confidence to go all out when the game is on the line.",
  },
  {
    src: "https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/73622f20-44b7-4fcf-b58f-f53a6195ac19/zoom-freak-4-team-basketball-shoes-p95BnP.png",
    name: "Zoom Freak 4 (Team)",
    price: "$72.97",
    desc: "Giannis is an incessant storm of stamina and skill that keeps coming at opponents for 4 quarters or more. The forward-thinking design of his latest signature shoe helps propel you down the court in a lightweight fit that moves with you. It can handle quick changes in direction on both sides of the floor, giving you side-to-side stability and multidirectional traction as you Euro step to the hoop.",
  },
  {
    src: "https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/e80e4dee-62f3-46e8-9bed-900e3e7e2326/giannis-immortality-2-basketball-shoes-X7xj7b.png",
    name: "Giannis Immortality 2",
    price: "$59.97",
    desc: "Morph into all-timer with the Giannis Immortality 2. A plush foam package combined with a more focused fit in the midfoot in a super lightweight design means you can maintain a high level of intensity and focus when victory hangs in the balance—just like Giannis in the clutch.",
  },
  {
    src: "https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/7fcbdd4f-2909-4e6a-974b-b556c22e00d0/kd15-basketball-shoes-10P3rj.png",
    name: "KD15",
    price: "$150",
    desc: "Clutch plays and impossible shots are all in a day's work for Kevin Durant. Give him the ball, and he makes the game look easy. The KD15 is stripped down to provide just what he wants: Plenty of bounce, a secure fit and a broken-in feel. Fewer materials underfoot and throughout the upper help minimize distractions while optimizing energy return and all-around comfort—vital for players like KD who never stop moving.",
  },
  {
    src: "https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/65dbe06e-a74a-41d3-bd08-8fde5503dd31/luka-1-basketball-shoes-69X9Vs.png",
    name: "Luka 1",
    price: "$110",
    desc: "Designed for #77 and made for every athlete craving speed and efficiency, Luka's debut delivers the goods. The first shoe with full-length Formula 23 foam, it has an ultra-supportive fit crafted with the step-back in mind. Meanwhile, strong and lightweight Flight Wire cables keep you feeling contained, whether you're playing indoors or out. This is the assist you've been waiting for—get out there and make your shot.",
  },
  {
    src: "https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/f5105d1c-6d28-43f5-a7c4-93269102481a/air-zoom-gt-jump-basketball-shoes-Mj4kHp.png",
    name: "Nike Air Zoom G.T. Jump",
    price: "$180",
    desc: "Feel like defying gravity? Meet one of Nike Basketball’s most responsive cushioning systems ever devised for game shoes. The double-stacked Zoom Air works in unison with an external jump frame made from PEBAX® that's lightweight, springy and strong. The woven exoskeleton-like upper helps you feel fully connected, so you can move with confidence and power and defeat the force that holds you down.",
  },
  {
    src: "https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/812e2ade-87d9-4413-84ee-735eae38c5f2/air-zoom-gt-cut-2-basketball-shoes-tmfmFl.png",
    name: "Nike Air Zoom G.T. Cut 2",
    price: "$170",
    desc: "The Nike Air Zoom G.T. Cut 2 helps you stop on a dime and accelerate back into the open lane in a low-to-the-ground design that helps increase court contact while switching direction. Separate the players from the playmakers in a model that’s built on creating separation but supportive enough to help you play all day.",
  },
  {
    src: "https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/ba01ac31-2cda-44c5-bea9-6928242bd706/lebron-nxxt-gen-x-faze-clan-basketball-shoes-55g4w1.png",
    name: "LeBron NXXT Gen x FaZe Clan",
    price: "$160",
    desc: "LeBron and FaZe Clan team up to bring you a collection rooted in sport, gaming and the culture. Whether you’re a dunk contest-like leaper or a below-the-rim wonder roaming the baseline, feel faster, lower to the court and assured in the LeBron NXXT Gen. We specifically tailored it to meet the demands of today’s fast-paced game, so that you can stay ahead of the opposition with your speed and force in all directions.",
  },
  {
    src: "https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/eef7599d-26a0-41cf-ad4d-b02acec42050/lebron-xx-uninterrupted-ep-basketball-shoes-ct1qVm.png",
    name: "LeBron XX UNINTERRUPTED EP",
    price: "$200",
    desc: "Two decades into a career exceeding every lofty expectation, LeBron James has refused to settle for anything less than greatness, even when he was the one setting the standard for generations to come. Now, his latest signature shoe is lighter, low to the ground and turbo-like. It’s unlike any design LeBron has donned before—comfortable and supportive, yet low-cut, feathery-fast and created to stay ahead of today’s frenzied style of play. The sandy pebble base and wavy blue vibes are vintage South Beach, nods to Bron’s budding interests both in and outside the game.",
  },
  {
    src: "https://static.nike.com/a/images/t_PDP_1728_v1/f_auto,q_auto:eco/932267a0-08b0-4039-9913-40f760777804/tatum-1-st-louis-basketball-shoes-98vPwk.png",
    name: 'Tatum 1 "St. Louis',
    price: "$120",
    desc: "“Everybody who knows me knows that my love for St. Louis runs deep…” So deep that we made a special edition colorway to celebrate Jay's hometown. The bold combo of red and white will make anyone from The Lou do a double-take. Fleur-de-lis designs on the heel and insole nod to the city’s flag. And Archer Avenue? Well, that’s where it all started for Jayson. Continue the journey by honoring his roots in the Tatum 1 \"St. Louis.",
  }
];

export default Inventory;
